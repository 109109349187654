import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import Wrapper from "../components/Wrapper"
import Email from "../components/icons/Email"
import { css } from "@emotion/core"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import BigTitle from "../components/BigTitle"
import Twitter from "../components/icons/Twitter"

const AuthorBlock = ({
  name,
  email,
  twitter,
  site,
}: {
  name: string
  email?: string
  twitter?: string
  site?: string
}) => {
  return (
    <p
      css={css`
        margin-top: 2em;
        padding-top: 1em;
        font-size: 0.9em;
        border-top: 1px dotted rgba(255, 255, 255, 0.1);
      `}
    >
      <p>
        <span
          css={css`
            font-size: 0.9em;
          `}
        >
          by
        </span>
        {` ${name}`}
      </p>
      {email && (
        <a
          css={css`
            width: 24px;
            display: inline-block;
            line-height: 1;
          `}
          href={`mailto:${email}`}
        >
          <Email />
        </a>
      )}
      {twitter && (
        <a
          css={css`
            width: 24px;
            display: inline-block;
            line-height: 1;
          `}
          target="_BLANK"
          href={`https://twitter.com/${twitter}`}
        >
          <Twitter />
        </a>
      )}
    </p>
  )
}

const BlogPostTemplate = ({ data, pageContext }) => {
  const post = data.mdx
  const { previous, next } = pageContext
  const { frontmatter } = post
  const { author: authorKey } = frontmatter
  const author = data.site.siteMetadata.authors.find(
    ({ name }) => name.toLowerCase() == authorKey.toLowerCase()
  ) || { name: authorKey }
  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <Img
        css={css`
          max-height: 250px;
          max-height: 40vh;
          object-fit: cover;
          background-size: cover;
          object-position: 50% 50%;
          background-position: 50% 50%;
        `}
        fluid={
          frontmatter.hero_image.childImageSharp.fluid
        }
        alt={frontmatter.title}
      />
      <Wrapper
        margin="2em"
        padding="2em"
        css={css`
          max-width: 900px;
        `}
      >
        <BigTitle>{post.frontmatter.title}</BigTitle>
        <p
          css={css`
            margin-top: 0;
            opacity: 0.8;
            margin-left: 1.6em;
            margin-bottom: 2em;
          `}
        >
          <span
            css={css`
              font-size: 0.8em;
            `}
          >
            {post.frontmatter.date}
          </span>
        </p>
        <div
          css={css`
            line-height: 2;
            margin: 0 auto;
          `}
        >
          <MDXRenderer>{post.body}</MDXRenderer>
          {author && <AuthorBlock {...author} />}
        </div>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Wrapper>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        authors {
          name
          email
          twitter
        }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        hero_image {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_tracedSVG
              #...GatsbyImageSharpFixed
            }
          }
        }
      }
      body
    }
  }
`
