import React from 'react'
import { css } from "@emotion/core";

export default ({ children }) => <h1
css={css`
  margin: 0 auto;
  text-align: center;
  font-size: 4em;
`}
>
{children}
</h1>